import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { ReduxState } from "../../../redux/store";
import { Dispatch, Action } from "redux";
import { createRooftop } from "../../../redux/actions/adminActions/adminRoofActions";

import Nav from "../../../components/Nav";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import ButtonSubmit from "../../../components/ButtonSubmit";

const mapStateToProps = ({ user }: ReduxState) => {
  const { orgId } = user;
  return { orgId };
};

const mapDipsatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    createRooftop: (orgId: number, rooftop: object) =>
      dispatch(createRooftop(orgId, rooftop)),
  };
};

interface Props {
  orgId: number;
  createRooftop: Function;
}

const CreateRooftop: FunctionComponent<Props> = ({ orgId, createRooftop }) => {
  const history: any = useHistory();

  //state
  const [name, setName] = useState("");
  const [platforms, setPlatforms] = useState([
    {
      name: "Google",
      url: "",
      enabled: true,
    },
    {
      name: "Yelp",
      url: "",
      enabled: true,
    },
  ]);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    platforms: "",
  });

  //events
  const handleFormSubmit = () => {
    let nameError = "";
    let platformsError = "";
    const enabled = platforms.filter((platform) => platform.enabled);

    if (!name) nameError = "Must not be empty";
    if (enabled.length === 0)
      platformsError = "Must have minimum 1 platform enabled";

    if (!nameError && !platformsError) {
      const rooftop = {
        name,
        review_urls: platforms,
        message_body: message,
        email_list: email,
      };

      createRooftop(orgId, rooftop);
      history.push("rooftops");
    } else {
      let errorsObject = {
        name: nameError,
        platforms: platformsError,
      };

      setErrors(errorsObject);
    }
  };

  return (
    <div>
      <Nav
        backButtonExists={true}
        middleExists={false}
        buttonText={""}
        buttonLink={""}
        handleButtonClick={() => false}
        adminHomeExists={true}
        logoIsLink={true}
      />
      <Card title={"Create a New Location"}>
        <Form submitForm={handleFormSubmit}>
          <Input
            labelText="Location Name"
            inputType="text"
            value={name}
            onChange={setName}
          />
          {errors.name && <p className="-mt-5 text-red-500">{errors.name}</p>}

          <TextArea
            labelText="Review Request Message Text"
            name="sms-message"
            value={message}
            placeholderText="Send us a review!"
            maxLength={80}
            onChange={setMessage}
          />

          <div className="mb-4">
            <span className="flex justify-between">
              <label>Select Review Platforms</label>
              <a
                href="https://docs.ozreputation.com/"
                target="_BLANK"
                rel="noopener noreferrer"
              >
                <p className="text-ozRepGreen text-right italic">
                  What's this?
                </p>
              </a>
            </span>

            <div className="border-solid border-2 border-ozRepGreen p-8 grid grid-cols-1 gap-y-6">
              {platforms.map((platform, index) => (
                <div key={index}>
                  <Input
                    labelText={platform.name}
                    name="url"
                    value={platform.url}
                    inputType="text"
                    required={platform.enabled ? true : false}
                    onChange={(url: string) => {
                      platform.url = url;
                      setPlatforms([...platforms]);
                    }}
                  />
                  <div className="flex items-center">
                    <span className="mr-2">Enabled:</span>
                    <input
                      checked={platform.enabled}
                      name="enabled"
                      type="checkbox"
                      onChange={() => {
                        platform.enabled = !platform.enabled;
                        setPlatforms([...platforms]);
                      }}
                    />
                  </div>
                  {errors.platforms && (
                    <p className="text-red-500">{errors.platforms}</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          <Input
            labelText="Email Notification List (seperate with ',')"
            inputType="text"
            value={email}
            onChange={setEmail}
          />
          <ButtonSubmit buttonText="Create" />
        </Form>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDipsatchToProps)(CreateRooftop);
