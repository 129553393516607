import React, { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AsYouType, formatIncompletePhoneNumber } from "libphonenumber-js";

import { connect } from "react-redux";
import { ReduxState } from "../redux/store";
import { Dispatch, Action } from "redux";
import { sendReviewRequest } from "../redux/actions/userActions";
import { getOrgData } from "../redux/actions/orgActions";

//NOT mapped to props via connect function
import { getOrgRoof } from "../redux/actions/orgActions";

import Nav from "../components/Nav";
import Card from "../components/Card";
import Form from "../components/Form";
import Input from "../components/Input";
import OrgLogo from "../components/OrgLogo";
import ButtonSubmit from "../components/ButtonSubmit";

const mapStateToProps = ({ user, org }: ReduxState) => {
  const { orgId, userId, defaultStore } = user;
  const { orgName, orgLogo } = org.orgData;
  return { orgName, orgLogo, userId, orgId, defaultStore };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {
    sendReviewRequest: (
      orgId: number,
      roofId: number,
      userId: number,
      customerInfo: object,
      review_urls: object[]
    ) =>
      dispatch(
        sendReviewRequest(orgId, roofId, userId, customerInfo, review_urls)
      ),
    getOrgData: (orgId: number) => dispatch(getOrgData(orgId)),
  };
};

interface Props {
  orgId: number;
  userId: number;
  orgName: string;
  orgLogo: string;
  defaultStore: number;
  getOrgData: Function;
  sendReviewRequest: Function;
}

const RequestReview: FunctionComponent<Props> = ({
  orgId,
  userId,
  orgName,
  orgLogo,
  defaultStore,
  getOrgData,
  sendReviewRequest,
}) => {
  let history: any = useHistory();

  //state
  const [name, setName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [storeName, setStoreName] = useState("");
  const [platforms, setPlatforms] = useState<any[]>([]);

  //if the user's default store is not set, take them to ChangeStore
  //so they can set it
  useEffect(() => {
    if (defaultStore === null || typeof defaultStore === "undefined") {
      history.replace("/change-location");
    }
    getOrgData(orgId);
  }, []);

  //fetch the default store's name to display
  useEffect(() => {
    if (defaultStore) {
      getOrgRoof(orgId, defaultStore)
        .then((res: any) => {
          setStoreName(res.data.rooftop.name);
          const reviewUrls = res.data.rooftop.review_urls;
          reviewUrls.forEach((url: any) => {
            if (!url.enabled) {
              url.disabled = true;
            } else {
              url.disabled = false;
            }
          });
          setPlatforms(reviewUrls);
        })
        .catch((err: any) => {
          //messaging
        });
    }
  }, [defaultStore]);

  //events
  const handleFormSubmit = () => {
    const customerInfo = { phone: phoneNum, name: name };
    const review_urls = platforms.map((platform) => {
      delete platform.disabled;
      return platform;
    });
    sendReviewRequest(orgId, defaultStore, userId, customerInfo, review_urls);
    setName("");
    setPhoneNum("");
  };

  const handleUpdatePhoneNumber = (value: any) => {
    let newValue: string = formatPhoneNum(value);

    // This allows for backspacing of non-number characters. This triggers
    // when you try to delete a character, but the formatter instantly re-adds it.
    if (newValue === phoneNum) {
      // If the character you tried to delete is a closing paren, then
      // remove all parentheses and delete the actual last number as well.
      if (newValue.indexOf(")") === newValue.length - 1) {
        newValue = newValue.replace(/\(|\)/g, "");
        setPhoneNum(newValue.slice(0, -1));
      }
    } else {
      setPhoneNum(new AsYouType("US").input(value));
    }
  };

  const formatPhoneNum = (phoneNumberString: string) => {
    return formatIncompletePhoneNumber(phoneNumberString, "US");
  };

  return (
    <div>
      <Nav
        backButtonExists={true}
        middleExists={true}
        buttonText={"Change Location"}
        buttonLink={"/change-location"}
        handleButtonClick={() => false}
        adminHomeExists={false}
        logoIsLink={true}
      />
      <Card title={"Send a Review Request"}>
        <OrgLogo
          orgName={orgName}
          rooftopName={storeName}
          logoSrc={orgLogo ? orgLogo : ""}
        />

        <Form submitForm={handleFormSubmit}>
          <Input
            labelText={"Customer Name"}
            inputType={"text"}
            value={name}
            required={true}
            onChange={setName}
          />

          <Input
            labelText={"Phone Number"}
            inputMode="numeric"
            inputType={"num"}
            value={phoneNum}
            required={true}
            onChange={handleUpdatePhoneNumber}
          />

          <p className="-mb-5">Select Platform(s) for Customer Review</p>
          {platforms.map((platform, index) => (
            <div key={index} className="pl-2">
              <input
                checked={platform.enabled}
                name="enabled"
                type="checkbox"
                disabled={platform.disabled}
                onChange={() => {
                  platforms.forEach((p, i) => {
                    if (p.enabled && i != index) {
                      platform.enabled = !platform.enabled;
                    }
                  });
                  setPlatforms([...platforms]);
                }}
                className="mr-2"
              />
              <span className={platform.disabled ? "line-through" : ""}>
                {platform.name}
              </span>
            </div>
          ))}

          <ButtonSubmit buttonText={"Send"} />
        </Form>
      </Card>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestReview);
